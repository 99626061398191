import {
  Component,
  Emit,
  Mixins,
  Prop,
  Vue,
  Watch
} from "vue-property-decorator";
import UlPieChart from "@/models/analysis/ul-pie-chart";
import PushDeliveryGet from "@/store/analysis/push-delivery/get";
import PushStatusGet from "@/store/analysis/push-status/get";
import PushProfileGet from "@/store/analysis/push-profile/get";
import Flash, { ErrorAlert } from "@/store/common/flash";
import AnalysisBase from "@/models/analysis/analysis-base";
import { ChartElement } from "@/models/analysis/analysis-base";
import { PushStatusGetRequest } from "@/api/analysis/push-status/request";
import { AnalysisGraphData } from "@/api/analysis/common/response";
import { PushProfileGetRequest } from "@/api/analysis/push-profile/request";
import { PushProfileGetResult } from "@/api/analysis/push-profile/response";
import { PushDeliveryInputOptions } from "@/models/analysis/push-notification";

@Component({
  components: {
    UlPieChart
  }
})
export default class AnalysisReservationChild extends Mixins(AnalysisBase) {
  @Prop() data!: AnalysisGraphData;

  @Watch("totalCountOnDrillDowned")
  didUpdateTotalCount(value: number) {
    this.updateTotalCountOnDrillDowned(value);
  }

  @Emit()
  public updateTotalCountOnDrillDowned(value: number) {
    return value;
  }

  drillDownHierarchy = 0;
  totalCountOnDrillDowned = 0;
  totalCounts: number[] = [0];

  isLoadedPushStatusData = false;
  isLoadedPushProfileData = false;

  get pushDeliveryChartData() {
    return this.getPieChartData(this.data);
  }

  get pushDeliveryChartOption() {
    return this.getPieChartOptions(this.data, this.drillDownPushStatus);
  }

  get pushStatusChartData() {
    return this.getPieChartData(PushStatusGet.getData);
  }

  get pushStatusChartOption() {
    return this.getPieChartOptions(
      PushStatusGet.getData,
      this.drillDownPushProfile
    );
  }

  get genderChartData() {
    return this.getPieChartData(PushProfileGet.getGenderData);
  }

  get genderChartOptions() {
    return this.getPieChartOptions(PushProfileGet.getGenderData);
  }

  get periodChartData() {
    return this.getPieChartData(PushProfileGet.getPeriodData);
  }

  get periodChartOptions() {
    return this.getPieChartOptions(PushProfileGet.getPeriodData);
  }

  get profile1ChartData() {
    return this.getPieChartData(PushProfileGet.getProfile1Data);
  }

  get profile1ChartOptions() {
    return this.getPieChartOptions(PushProfileGet.getProfile1Data);
  }

  get profile2ChartData() {
    return this.getPieChartData(PushProfileGet.getProfile2Data);
  }

  get profile2ChartOptions() {
    return this.getPieChartOptions(PushProfileGet.getProfile2Data);
  }

  get profile3ChartData() {
    return this.getPieChartData(PushProfileGet.getProfile3Data);
  }

  get profile3ChartOptions() {
    return this.getPieChartOptions(PushProfileGet.getProfile3Data);
  }

  get profile4ChartData() {
    return this.getPieChartData(PushProfileGet.getProfile4Data);
  }

  get profile4ChartOptions() {
    return this.getPieChartOptions(PushProfileGet.getProfile4Data);
  }

  get profile5ChartData() {
    return this.getPieChartData(PushProfileGet.getProfile5Data);
  }

  get profile5ChartOptions() {
    return this.getPieChartOptions(PushProfileGet.getProfile5Data);
  }

  /**
   * createdライフサイクルフック
   */
  async created() {}

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await PushStatusGet.clearResponse();
    await PushProfileGet.clearResponse();
  }

  async fetchPushStatus(request: PushStatusGetRequest): Promise<boolean> {
    this.isLoadedPushStatusData = false;
    await Flash.clear();
    await PushStatusGet.get(request);
    if (!PushStatusGet.isSuccess) {
      await Flash.setErrorNow({
        message: PushStatusGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedPushStatusData = true;
    return PushStatusGet.isSuccess;
  }

  async fetchPushProfile(request: PushProfileGetRequest): Promise<boolean> {
    this.isLoadedPushProfileData = false;
    await Flash.clear();
    await PushProfileGet.get(request);
    if (!PushProfileGet.isSuccess) {
      await Flash.setErrorNow({
        message: PushProfileGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedPushProfileData = true;
    return PushProfileGet.isSuccess;
  }

  onClickBack() {
    this.drillDownHierarchy -= 1;
    this.totalCountOnDrillDowned = this.totalCounts[this.drillDownHierarchy];
  }

  async drillDownPushStatus(
    event: MouseEvent | undefined,
    activeElements: Array<{}> | undefined
  ) {
    if (!activeElements || (activeElements && activeElements.length <= 0)) {
      return;
    }

    const element = activeElements[0] as ChartElement;
    const pushStatusGetRequest: PushStatusGetRequest = {
      startDate: PushDeliveryGet.getGetRequest.startDate,
      endDate: PushDeliveryGet.getGetRequest.endDate,
      shopId: PushDeliveryGet.getGetRequest.searchShopId,
      pushId: Number(this.data.labelsData[element._index])
    };
    await this.fetchPushStatus(pushStatusGetRequest);

    const data = this.data.datasets[0].data;
    if (data != null) {
      this.totalCountOnDrillDowned = Number(data[element._index]);
      this.totalCounts[1] = this.totalCountOnDrillDowned;
    }
    this.drillDownHierarchy = 1;
  }

  async drillDownPushProfile(
    event: MouseEvent | undefined,
    activeElements: Array<{}> | undefined
  ) {
    if (
      !activeElements ||
      (activeElements && activeElements.length <= 0) ||
      this.isDashboard
    ) {
      return;
    }

    const pushStatusGetRequest = PushStatusGet.getGetRequest;
    const PushProfileGetRequest: PushProfileGetRequest = {
      startDate: pushStatusGetRequest.startDate,
      endDate: pushStatusGetRequest.endDate,
      shopId: pushStatusGetRequest.shopId,
      pushId: pushStatusGetRequest.pushId,
      status: null
    };

    const element = activeElements[0] as ChartElement;
    const data = PushStatusGet.getData as AnalysisGraphData;
    PushProfileGetRequest.status = Number(data.labelsData[element._index]);

    await this.fetchPushProfile(PushProfileGetRequest);

    const datasetsData = data.datasets[0].data;
    if (datasetsData != null) {
      this.totalCountOnDrillDowned = Number(datasetsData[element._index]);
      this.totalCounts[2] = this.totalCountOnDrillDowned;
    }

    this.drillDownHierarchy = 2;
  }

  @Watch("data")
  watchGraphData(newData: AnalysisGraphData) {
    if (newData) {
      this.drillDownHierarchy = 0;
    }
  }
}
