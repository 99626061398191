import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{attrs:{"hide-default-header":_vm.hideDefaultHeader,"hide-default-footer":_vm.hideDefaultFooter,"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.totalCount,"loading":_vm.loading,"options":_vm.dataOptions,"footer-props":{ itemsPerPageOptions: _vm.itemsPerPageOptions },"item-key":_vm.itemKey,"no-data-text":"表示できるデータがありません"},on:{"update:options":_vm.updateDataOptions},scopedSlots:_vm._u([{key:"item.reorder",fn:function(ref){
var header = ref.header;
var item = ref.item;
return [(header.always || item.writeFlg)?_c(VIcon,[_vm._v("reorder")]):_c('span',{staticClass:"not-sortable"})]}},{key:"item.sort",fn:function(ref){
var header = ref.header;
var item = ref.item;
return [_c(VSheet,{attrs:{"width":"50px"}},[_c(VTextField,{staticClass:"ma-0 pa-0",attrs:{"outlined":"","dense":"","hide-details":"","reverse":"","value":item.sort},on:{"change":function($event){return _vm.sortChangeCallback(item.id, $event)}}})],1)]}},{key:"item.image",fn:function(ref){
var header = ref.header;
var item = ref.item;
return [_c(VImg,{staticClass:"ma-2",attrs:{"width":"84","src":item.imagePath,"alt":""}})]}},{key:"item.video",fn:function(ref){
var header = ref.header;
var item = ref.item;
return [_c('div',{staticStyle:{"margin":"30px 0"}},[_c('video',{attrs:{"src":item.videoSrc,"controls":"controls","height":"150","width":"200","controlslist":"nodownload"}})])]}},{key:"item.delete",fn:function(ref){
var header = ref.header;
var item = ref.item;
return [(String(item.externalLink) == 'undefined' || item.externalLink == 1)?_c(VBtn,{staticStyle:{"margin-left":"-20px"},attrs:{"disabled":!item.deleteFlg,"outlined":"","rounded":"","small":"","color":"red"},on:{"click":function($event){return _vm.deleteClickCallback(item)}}},[_vm._v(_vm._s(header.label)+" ")]):_vm._e()]}},{key:"item.edit",fn:function(ref){
var header = ref.header;
var item = ref.item;
return [_c(VBadge,{attrs:{"color":"pink","overlap":""}},[_c(VBtn,{attrs:{"disabled":!item.writeFlg,"outlined":"","rounded":"","small":"","color":"primary"},on:{"click":function($event){return _vm.editClickCallback(item)}}},[_vm._v(_vm._s(header.label)+" ")])],1)]}},{key:"item.fixed",fn:function(ref){
var header = ref.header;
var item = ref.item;
return [(item.fixedFlg == '設定なし')?_c('div',[_c(VBtn,{attrs:{"disabled":!item.fixedFlg,"outlined":"","rounded":"","small":"","color":"primary"},on:{"click":function($event){return _vm.changeFixedClickCallback(item)}}},[_vm._v("固定設定 ")])],1):_c('div',[_c(VBtn,{attrs:{"disabled":!item.fixedFlg,"outlined":"","rounded":"","small":"","color":"red"},on:{"click":function($event){return _vm.changeFixedClickCallback(item)}}},[_vm._v("固定解除 ")])],1)]}},{key:"item.action",fn:function(ref){
var header = ref.header;
var item = ref.item;
return [_c(VBadge,{attrs:{"color":"pink","overlap":""},scopedSlots:_vm._u([(item.unapprovedCount && item.unapprovedCount > 0)?{key:"badge",fn:function(){return [_c('span',[_vm._v(_vm._s(item.unapprovedCount))])]},proxy:true}:(item.badge && item.badge > 0)?{key:"badge",fn:function(){return [_c('span',[_vm._v(_vm._s(item.badge))])]},proxy:true}:null],null,true)},[_c(VBtn,{staticClass:"actionBtn",attrs:{"disabled":!item.actionFlg,"outlined":"","rounded":"","small":"","color":"primary"},on:{"click":function($event){return _vm.actionClickCallback(item)}}},[_vm._v(_vm._s(header.label)+" ")])],1)]}},{key:"item.movie",fn:function(ref){
var header = ref.header;
var item = ref.item;
return [_c(VBadge,{attrs:{"color":"pink","overlap":""}},[_c(VBtn,{staticStyle:{"margin-right":"-75px"},attrs:{"disabled":!item.movieFlg,"outlined":"","rounded":"","small":"","color":"primary"},on:{"click":function($event){return _vm.movieClickCallback(item)}}},[_vm._v(_vm._s(header.label)+" ")])],1)]}},{key:"item.download",fn:function(ref){
var header = ref.header;
var item = ref.item;
return [_c(VBadge,{attrs:{"color":"pink","overlap":""}},[_c(VBtn,{staticStyle:{"margin-left":"-65px"},attrs:{"disabled":!item.downloadFlg,"outlined":"","rounded":"","small":"","color":"primary"},on:{"click":function($event){return _vm.downloadClickCallback(item)}}},[_vm._v(_vm._s(header.label)+" ")])],1)]}},{key:"item.unlink",fn:function(ref){
var header = ref.header;
var item = ref.item;
return [(item.cardNumber === null)?_c('div',{staticClass:"text-right"},[_c(VBtn,{attrs:{"disabled":"","outlined":"","rounded":"","small":"","color":"red"},on:{"click":function($event){return _vm.unlinkClickCallback(item)}}},[_vm._v(_vm._s(header.label)+" ")])],1):_c('div',{staticClass:"text-right"},[_c(VBtn,{attrs:{"disabled":!item.writeFlg,"outlined":"","rounded":"","small":"","color":"red"},on:{"click":function($event){return _vm.unlinkClickCallback(item)}}},[_vm._v(_vm._s(header.label)+" ")])],1)]}},{key:"item.custom",fn:function(ref){
var header = ref.header;
var item = ref.item;
return [_vm._t("custom",null,{"header":header,"item":item})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }