import { Component, Prop, Vue } from "vue-property-decorator";
import { AnalysisGraphData } from "@/api/analysis/common/response";
import Chart, { ChartDataSets } from "chart.js";
import { saveAs } from "file-saver";
import Encoding from "encoding-japanese";

export interface ChartElement {
  _index: number;
  _datasetIndex: number;
}

@Component
export default class AnalysisBase extends Vue {
  @Prop({ default: false })
  isDashboard?: boolean;

  @Prop({ default: false })
  editFocused!: boolean;

  @Prop({ default: true })
  isNeededActiveOnDashBoard?: boolean;

  alpha = "BF";
  colors = [
    "#eb3336" + this.alpha,
    "#497eb3" + this.alpha,
    "#75a33a" + this.alpha,
    "#8e559f" + this.alpha,
    "#5ecfd3" + this.alpha,
    "#f08533" + this.alpha,
    "#aa8d2e" + this.alpha,
    "#7f82c8" + this.alpha,
    "#bfe649" + this.alpha,
    "#b53d61" + this.alpha
  ];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  getBarChartData(data: AnalysisGraphData | null) {
    if (data && data.datasets) {
      data.datasets.map((d, i) => {
        d.type = "bar";
        d.backgroundColor = this.colors[i];
        d.fill = false;
        d.pointRadius = 0;
        d.pointHitRadius = 10;
      });
      const chartData: Chart.ChartData = {
        labels: data.labels,
        datasets: data.datasets as ChartDataSets[]
      };
      return chartData;
    } else {
      return null;
    }
  }

  getBarChartOptions(
    data: AnalysisGraphData | null,
    isStacked = false,
    isShowLegend = false,
    func?: (event?: MouseEvent, activeElements?: Array<{}>) => void
  ) {
    if (data) {
      if (data.options == null) {
        data.options = {};
      }

      data.options.responsive = true;
      data.options.maintainAspectRatio = false;
      data.options.animation = {
        animateScale: true
      };
      if (data.options.title) {
        data.options.title.display = true;
      }
      data.options.legend = {
        display: isShowLegend
      };
      data.options.layout = {
        padding: {
          left: 15,
          right: 30
        }
      };
      data.options.scales = {
        xAxes: [
          {
            stacked: isStacked,
            offset: true,
            ticks: {
              padding: 10
            },
            gridLines: {
              drawTicks: false
            }
          }
        ],
        yAxes: [
          {
            stacked: isStacked,
            ticks: {
              padding: 10,
              beginAtZero: true,
              //stepSize: 1.0
            },
            gridLines: {
              drawTicks: false
            }
          }
        ]
      };
      data.options.events = [
        "mousemove",
        "mouseout",
        "click",
        "touchstart",
        "touchmove",
        "touchend"
      ];
      data.options.onClick = (
        event?: MouseEvent,
        activeElements?: Array<{}>
      ) => {
        if (func) func(event, activeElements);
      };
      return data.options;
    } else {
      return null;
    }
  }

  getPieChartData(data: AnalysisGraphData | null) {
    if (data && data.datasets) {
      data.datasets.map((d, i) => {
        d.type = "pie";
        d.backgroundColor = this.colors;
      });
      const chartData: Chart.ChartData = {
        labels: data.labels,
        datasets: data.datasets as ChartDataSets[]
      };
      return chartData;
    } else {
      return null;
    }
  }

  getPieChartOptions(
    data: AnalysisGraphData | null,
    func?: (event?: MouseEvent, activeElements?: Array<{}>) => void
  ) {
    if (data) {
      if (data.options == null) {
        data.options = {};
      }
      data.options.responsive = true;
      data.options.maintainAspectRatio = false;
      data.options.animation = {
        animateScale: true
      };
      if (data.options.title) {
        data.options.title.display = true;
      }
      data.options.layout = {
        padding: {
          left: 15,
          right: 30
        }
      };
      data.options.scales = {
        display: false
      };
      data.options.events = [
        "mousemove",
        "mouseout",
        "click",
        "touchstart",
        "touchmove",
        "touchend"
      ];
      data.options.onClick = (
        event?: MouseEvent,
        activeElements?: Array<{}>
      ) => {
        if (func) func(event, activeElements);
      };
      return data.options;
    } else {
      return null;
    }
  }

  getLineChartData(data: AnalysisGraphData | null) {
    if (data && data.datasets) {
      data.datasets.map((d, i) => {
        d.type = "line";
        d.fill = false;
        d.backgroundColor = "#ffffff";
        d.borderColor = this.colors[i];
        d.pointRadius = 5;
        d.pointBorderWidth = 3;
        d.pointHitRadius = 10;
      });
      const chartData: Chart.ChartData = {
        labels: data.labels,
        datasets: data.datasets as ChartDataSets[]
      };
      return chartData;
    } else {
      return null;
    }
  }

  getLineChartOptions(
    data: AnalysisGraphData | null,
    isShowLegend = false,
    func?: (event?: MouseEvent, activeElements?: Array<{}>) => void
  ) {
    if (data) {
      if (data.options == null) {
        data.options = {};
      }
      data.options.responsive = true;
      data.options.maintainAspectRatio = false;
      data.options.showLines = true;
      data.options.animation = {
        animateScale: true
      };
      if (data.options.title) {
        data.options.title.display = true;
      }
      data.options.legend = {
        display: isShowLegend
      };
      data.options.layout = {
        padding: {
          left: 15,
          right: 30
        }
      };
      data.options.scales = {
        xAxes: [
          {
            offset: true,
            ticks: {
              padding: 10
            },
            gridLines: {
              drawTicks: false,
              zeroLineWidth: 0
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              padding: 10,
              beginAtZero: true,
              stepSize: 1
            },
            gridLines: {
              drawTicks: false,
              zeroLineWidth: 0
            }
          }
        ]
      };
      data.options.events = [
        "mousemove",
        "mouseout",
        "click",
        "touchstart",
        "touchmove",
        "touchend"
      ];
      data.options.onClick = (
        event?: MouseEvent,
        activeElements?: Array<{}>
      ) => {
        if (func) func(event, activeElements);
      };
      return data.options;
    } else {
      return null;
    }
  }

  stringFromDate(date: Date) {
    return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
      "0" + date.getDate()
    ).slice(-2)}`.replace(/[\n\r]/g, "");
  }

  /**
   * 日付のdividerを/から-に変える
   *
   * @param date 日付の文字列
   */
  replaceHyphenToSlash(date: string) {
    return date.replace(/-/g, "/");
  }

  replaceSlashToHyphen(date: string) {
    return date.replace(/\//g, "-");
  }

  saveCSV(csvString: string, fileName: string) {
    const csvStringArray = Encoding.stringToCode(csvString);
    const sJISArray = Encoding.convert(csvStringArray, "SJIS", "UNICODE");
    const uint8Array = new Uint8Array(sJISArray);
    const blob = new Blob([uint8Array], {
      type: "text/csv"
    });
    saveAs(blob, fileName);
  }
}
