import service from "@/api/service";
import { PushProfileGetRequest } from "@/api/analysis/push-profile/request";
import { PushProfileGetResponse } from "@/api/analysis/push-profile/response";

/**
 * プッシュ-属性統計APIをコールします。
 *
 * @param getRequest プッシュ-属性統計のリクエストボディ
 * @return PushProfileGetResponse
 */
export async function get(getRequest: PushProfileGetRequest) {
  const response = await service.post("/analysis-push-profile", getRequest);
  return response.data as PushProfileGetResponse;
}
