import service from "@/api/service";
import { PushReadRateGetRequest } from "@/api/analysis/push-read-rate/request";
import { PushReadRateGetResponse } from "@/api/analysis/push-read-rate/response";

/**
 * プッシュ通知開封率取得APIをコールします。
 *
 * @param getRequest プッシュ通知開封率取得のリクエストボディ
 * @return PushReadRateGetResponse
 */
export async function get(getRequest: PushReadRateGetRequest) {
  const response = await service.post("/analysis-push-read-rate", getRequest);
  return response.data as PushReadRateGetResponse;
}
