import { Component, Mixins, Vue, Watch } from "vue-property-decorator";
import AnalysisBase from "@/models/analysis/analysis-base";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import Flash, { ErrorAlert } from "@/store/common/flash";
import ShopGet from "@/store/shop/get";
import PushNotificationChart from "@/views/analysis/PushNotificationChart.vue";
import PushDeliveryGet from "@/store/analysis/push-delivery/get";
import { PushDeliveryGetRequest } from "@/api/analysis/push-delivery/request";
import Admin from "@/store/admin/admin";
import UIDataTable from "@/components/UIDataTable.vue";
import PushReadRateGet from "@/store/analysis/push-read-rate/get";
import { TableOptions } from "@/api/request";
import { PushReadRateGetRequest } from "@/api/analysis/push-read-rate/request";
import { PushReadRateCSVGetRequest } from "@/api/analysis/push-read-rate-csv/request";
import * as PushReadRateCSVAPI from "@/api/analysis/push-read-rate-csv";
import _ from "lodash";

export interface PushDeliveryInputOptions {
  startDate: string;
  endDate: string;
  selectShop: { id: string; name: string } | null;
}

@Component({
  components: {
    UlContentHeader,
    UIDatePicker,
    PushNotificationChart,
    UIDataTable
  }
})
export default class AnalysisPushNotification extends Mixins(AnalysisBase) {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "プッシュ";
  headingSub = "Push notification";
  breadCrumbs = [{ text: "プッシュ", disabled: true }];

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoadedShopData = false;
  isLoadedPushDeliveryData = false;
  isLoadingPushReadRate = false;

  // DatePickerの表示フラグ
  startDateModal = null;
  endDateModal = null;

  // 検索入力オプション
  inputOptions: PushDeliveryInputOptions = {
    startDate: this.initDates[0],
    endDate: this.initDates[1],
    selectShop: null
  };

  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "配信タイトル", value: "title", sortable: false },
    { text: "送信日", value: "date" },
    { text: "Android 未読", value: "unreadAndroid", sortable: false },
    { text: "Android 既読", value: "readAndroid", sortable: false },
    { text: "Android 配信数", value: "totalAndroid", sortable: false },
    { text: "Android 開封率", value: "readRateAndroid", sortable: false },
    { text: "iOS 未読", value: "unreadIos", sortable: false },
    { text: "iOS 既読", value: "readIos", sortable: false },
    { text: "iOS 配信数", value: "totalIos", sortable: false },
    { text: "iOS 開封率", value: "readRateIos", sortable: false }
  ];

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = {
    page: 1,
    displayCount: 20,
    sortKey: "date",
    sortOrder: "desc"
  } as TableOptions;

  totalCountOnDrillDowned = 0;

  get startDateForView() {
    return this.replaceHyphenToSlash(this.inputOptions.startDate);
  }
  set startDateForView(date) {
    this.inputOptions.startDate = this.replaceSlashToHyphen(date);
  }

  get endDateForView() {
    return this.replaceHyphenToSlash(this.inputOptions.endDate);
  }
  set endDateForView(date) {
    this.inputOptions.endDate = this.replaceSlashToHyphen(date);
  }

  get shopItems() {
    return ShopGet.getItems;
  }

  get initDates() {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    const firstDate = new Date(y, m, 1);
    const lastDate = new Date(y, m + 1, 0);
    return [this.stringFromDate(firstDate), this.stringFromDate(lastDate)];
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    if (this.editFocused || !this.isNeededActiveOnDashBoard) {
      return false;
    }

    await this.fetchShopList();
    if (!Admin.isAdmin) {
      this.inputOptions.selectShop = ShopGet.getItems[0];
      this.fetchRootChartData();
    }
  }

  async fetchRootChartData() {
    await this.fetchPushDelivery();
    await this.fetchPushReadRate();
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await ShopGet.clearResponse();
    await PushDeliveryGet.clearResponse();
  }

  /**
   * 総件数
   */
  get totalCount() {
    return this.totalCountOnDrillDowned > 0
      ? this.totalCountOnDrillDowned
      : PushDeliveryGet.getTotalCount;
  }

  get pushDeliveryData() {
    return PushDeliveryGet.getData;
  }

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   */
  get tableItems() {
    return PushReadRateGet.getItems;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get pushTotalCount() {
    return PushReadRateGet.getTotalCount;
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchShopList(): Promise<boolean> {
    this.isLoadedShopData = false;
    await ShopGet.get();
    if (!ShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: ShopGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedShopData = true;
    return ShopGet.isSuccess;
  }

  /**
   * グラフデータを取得する処理
   */
  async fetchPushDelivery(): Promise<boolean> {
    this.isLoadedPushDeliveryData = false;
    await Flash.clear();
    const pushDeliveryGetRequest: PushDeliveryGetRequest = {
      startDate: this.startDateForView,
      endDate: this.endDateForView,
      searchShopId:
        this.inputOptions.selectShop != null
          ? Number(this.inputOptions.selectShop.id)
          : null
    };
    await PushDeliveryGet.get(pushDeliveryGetRequest);
    if (!PushDeliveryGet.isSuccess) {
      await Flash.setErrorNow({
        message: PushDeliveryGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedPushDeliveryData = true;
    //NEW_DEV-1840 cyber start
    this.totalCountOnDrillDowned = PushDeliveryGet.getTotalCount;
    //NEW_DEV-1840 cyber end
    return PushDeliveryGet.isSuccess;
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    await this.fetchPushReadRate();
  }

  /**
   * 検索処理
   */
  async fetchPushReadRate() {
    this.isLoadingPushReadRate = true;
    let request = this.createPushReadRateGetRequest(
      this.inputOptions.selectShop != null
        ? Number(this.inputOptions.selectShop.id)
        : null
    );
    await PushReadRateGet.get(request);
    if (!PushReadRateGet.isSuccess) {
      await Flash.setErrorNow({
        message: PushReadRateGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    await PushReadRateGet.addDateProperty(PushReadRateGet.getGetResponse);
    this.isLoadingPushReadRate = false;
  }

  /**
   * dataから検索用のリクエストを作成する
   */
  private createPushReadRateGetRequest(
    shopId: number | null
  ): PushReadRateGetRequest {
    const request = this.tableOptions as PushReadRateGetRequest;
    request.searchShopId = shopId;
    request.startDate = this.startDateForView;
    request.endDate = this.endDateForView;
    request.page = this.tableOptions.page;
    return request;
  }

  async fetchPushReadRateCSV() {
    let request = this.createPushReadRateCSVGetRequest(
      this.inputOptions.selectShop != null
        ? Number(this.inputOptions.selectShop.id)
        : null
    );

    const pushReadRateCSVGetResponse = await PushReadRateCSVAPI.get(request);
    if (
      pushReadRateCSVGetResponse &&
      pushReadRateCSVGetResponse.statusCd !== 200
    ) {
      await Flash.setErrorNow({
        message: pushReadRateCSVGetResponse.message,
        showReloadButton: false
      } as ErrorAlert);
      return false;
    }

    this.saveCSV(
      pushReadRateCSVGetResponse.results.csvString,
      pushReadRateCSVGetResponse.results.fileName
    );
  }

  private createPushReadRateCSVGetRequest(
    shopId: number | null
  ): PushReadRateCSVGetRequest {
    const request = _.cloneDeep(this.tableOptions) as PushReadRateGetRequest;
    request.searchShopId = shopId;
    request.startDate = this.startDateForView;
    request.endDate = this.endDateForView;
    request.sortKey = this.tableOptions.sortKey;
    request.sortOrder = this.tableOptions.sortOrder;
    delete request.page;
    delete request.displayCount;
    return request as PushReadRateCSVGetRequest;
  }

  updateTotalCountOnDrillDowned(totalCount: number) {
    this.totalCountOnDrillDowned = totalCount;
  }
}
