import { Component, Mixins, Prop } from "vue-property-decorator";
import { Pie, mixins } from "vue-chartjs";
import Chart from "chart.js";

@Component
export default class UlPieChart extends Mixins(Pie, mixins.reactiveProp) {
  @Prop() chartData!: Chart.ChartData;
  @Prop() chartOptions!: Chart.ChartOptions;

  public mounted() {
    this.renderChart(this.chartData, this.chartOptions);
  }
}
