import service from "@/api/service";
import { PushReadRateCSVGetRequest } from "@/api/analysis/push-read-rate-csv/request";
import { DownloadCSV, DownloadCSVResponse } from "@/api/response";

/**
 * プッシュ通知開封率CSV出力APIをコールします。
 *
 * @param getRequest プッシュ通知開封率CSV出力のリクエストボディ
 * @return response
 */
export async function get(getRequest: PushReadRateCSVGetRequest) {
  const response = await service.postReceiveCSV(
    "/analysis-push-read-rate-csv",
    getRequest
  );
  return response as DownloadCSVResponse;
}
