import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { PushProfileGetRequest } from "@/api/analysis/push-profile/request";
import { PushProfileGetResponse } from "@/api/analysis/push-profile/response";
import * as AnalyticsPushProfileAPI from "@/api/analysis/push-profile";
import { isSuccess } from "@/api/response";
import { AnalysisGraphData } from "@/api/analysis/common/response";

const MODULE_NAME = "analysis/push-profile/get";

/**
 *  プッシュ-ステータスAPI（/analysis-push-status）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getRequest: PushProfileGetRequest = {} as PushProfileGetRequest;
  getResponse: PushProfileGetResponse = {} as PushProfileGetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getGenderData() {
    if (this.getResponse.results && this.getResponse.results["genderData"]) {
      return this.getResponse.results.genderData.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getPeriodData() {
    if (this.getResponse.results && this.getResponse.results["periodData"]) {
      return this.getResponse.results.periodData.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getProfile1Data() {
    if (this.getResponse.results && this.getResponse.results["profile1Data"]) {
      return this.getResponse.results.profile1Data.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getProfile2Data() {
    if (this.getResponse.results && this.getResponse.results["profile2Data"]) {
      return this.getResponse.results.profile2Data.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getProfile3Data() {
    if (this.getResponse.results && this.getResponse.results["profile3Data"]) {
      return this.getResponse.results.profile3Data.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getProfile4Data() {
    if (this.getResponse.results && this.getResponse.results["profile4Data"]) {
      return this.getResponse.results.profile4Data.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getProfile5Data() {
    if (this.getResponse.results && this.getResponse.results["profile5Data"]) {
      return this.getResponse.results.profile5Data.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }

  // MutationActions
  @MutationAction
  async get(getRequest: PushProfileGetRequest) {
    const getResponse = await AnalyticsPushProfileAPI.get(getRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as PushProfileGetResponse
    };
  }
}

export default getModule(Get);
