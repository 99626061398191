import service from "@/api/service";
import { PushDeliveryGetRequest } from "@/api/analysis/push-delivery/request";
import { PushDeliveryGetResponse } from "@/api/analysis/push-delivery/response";

/**
 * プッシュ配信数APIをコールします。
 *
 * @param getRequest プッシュ配信数のリクエストボディ
 * @return PushDeliveryGetResponse
 */
export async function get(getRequest: PushDeliveryGetRequest) {
  const response = await service.post("/analysis-push-delivery", getRequest);
  return response.data as PushDeliveryGetResponse;
}
