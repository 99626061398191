import service from "@/api/service";
import { PushStatusGetRequest } from "@/api/analysis/push-status/request";
import { PushStatusGetResponse } from "@/api/analysis/push-status/response";

/**
 * プッシュ-ステータス別APIをコールします。
 *
 * @param getRequest プッシュ-ステータス別のリクエストボディ
 * @return PushStatusGetResponse
 */
export async function get(getRequest: PushStatusGetRequest) {
  const response = await service.post("/analysis-push-status", getRequest);
  return response.data as PushStatusGetResponse;
}
