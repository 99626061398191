import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { PushStatusGetRequest } from "@/api/analysis/push-status/request";
import { PushStatusGetResponse } from "@/api/analysis/push-status/response";
import * as AnalyticsPushStatusAPI from "@/api/analysis/push-status";
import { isSuccess } from "@/api/response";
import { AnalysisGraphData } from "@/api/analysis/common/response";

const MODULE_NAME = "analysis/push-status/get";

/**
 *  プッシュ-ステータスAPI（/analysis-push-status）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getRequest: PushStatusGetRequest = {} as PushStatusGetRequest;
  getResponse: PushStatusGetResponse = {} as PushStatusGetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getData() {
    if (this.getResponse.results) {
      return this.getResponse.results.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getTotalCount() {
    if (this.getResponse.results) {
      return this.getResponse.results.totalCount || 0;
    } else {
      return 0;
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }

  // MutationActions
  @MutationAction
  async get(getRequest: PushStatusGetRequest) {
    const getResponse = await AnalyticsPushStatusAPI.get(getRequest);
    return {
      getResponse,
      getRequest
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as PushStatusGetResponse
    };
  }
}

export default getModule(Get);
