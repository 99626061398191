




































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

interface DatePicker extends Vue {
  save(val: string): void;
}

@Component
export default class UIDatePicker extends Vue {
  @Prop() placeholder?: string;
  @Prop() label?: string;
  @Prop() defaultDate?: string | null;
  @Prop() dateChangeCallback!: (date: string | null) => void;
  @Prop() dateChangeCallback!: (date: string | null) => void;
  @Prop() disableDates?: {} | null;

  // 変動値
  // 日付 (yyyy-mm-dd)
  pickerDate = null as string | null;

  // フォーマットされた日付 (yyyy/mm/dd)
  dateFormatted = "" as string | null;

  // モーダルのON/OFF
  modal = false;

  // カレンダー非活性
  calendarDisplay = false;

  /**
   * defaultDate propsのウォッチャー
   * defaultDate props を pickerDate data と dateFormatted data に反映させます。
   *
   * @param newDate 変更後のdefaultDate
   * @param oldDate 変更前のdefaultDate
   */
  @Watch("defaultDate")
  watchDefaultDate(newDate: string | null, oldDate: string | null) {
    if (newDate) {
      this.onFocus();
      this.dateFormatted = UIDatePicker.formatDate(newDate);
      this.calendarDisplay = true;
    } else {
      this.dateFormatted = null;
    }
  }

  /**
   * dateFormatted dataのウォッチャー
   * dateFormatted data が null になった場合、 pickerDate data を初期化し、コールバックを実行します。
   *
   * @param newDate 変更前のdateFormatted
   * @param oldDate 変更後のdateFormatted
   */
  @Watch("dateFormatted")
  watchDateFormatted(newDate: string | null, oldDate: string | null) {
    if (!newDate) {
      this.pickerDate = null;
      this.dateChangeCallback && this.dateChangeCallback(this.pickerDate);
    }
  }

  created() {
    if (this.defaultDate) {
      this.pickerDate = this.defaultDate;
      this.dateFormatted = UIDatePicker.formatDate(this.defaultDate);
      this.calendarDisplay = true;
    } else if (!this.disableDates) {
      this.calendarDisplay = true;
    }
  }

  /**
   * OKボタンが押下された時のイベントハンドラー
   * フォーマットした日付を渡してdateChangeCallbackを実行する
   *
   * @param date 日付
   */
  onClickOk(date: string) {
    const dialog = this.$refs.dialog as DatePicker;
    dialog.save(date);
    this.dateFormatted = UIDatePicker.formatDate(date);
    this.dateChangeCallback(this.pickerDate);
  }

  /**
   * Cancelボタンが押下された時のイベントハンドラー
   * 日付がクリアされている場合はdateを初期化する
   * フォーマットした日付を渡してdateChangeCallbackを実行する
   */
  onClickCancel() {
    this.modal = false;
    if (this.dateFormatted == null) {
      const dialog = this.$refs.dialog as DatePicker;
      dialog.save("");
    }
  }

  /**
   * v-text-fieldにフォーカスが当たった時のイベントハンドラー
   */
  onFocus() {
    if (this.dateFormatted) {
      return;
    }
    let date: Date | null;
    if (this.defaultDate) {
      date = new Date(this.defaultDate);
    } else {
      date = new Date();
    }
    date.setTime(date.getTime() + 9 * 60 * 60 * 1000); // UTCで返却されるのでJSTに変換する
    this.pickerDate = date.toISOString().substr(0, 10);
  }
  /**
   * 日付を非活性にする
   * @return {[type]} [description]
   */
  allowedDate(val) {
    if (!this.disableDates) {
      return true;
    }

    let day = new Date(val);
    let dayString = day.getFullYear() + '-' + day.getMonth()  + '-' +  day.getDate();
    if (this.disableDates.dates) {
      for (let i = 0; i < this.disableDates.dates.length; i++) {
        if (this.disableDates.dates[i] == dayString) {
          return false;
        }
      }
    }
    if (this.disableDates.weeks) {
      for (let i = 0; i < this.disableDates.weeks.length; i++) {
        if (this.disableDates.weeks[i] == day.getDay()) {
          return false;
        }
      }
    }

    let today = new Date();
    let todayString = today.getFullYear() + '-' + today.getMonth()  + '-' +  today.getDate();
    if (this.disableDates.current && (today.getTime() > day.getTime()) && (dayString != todayString)) {
      return false;
    }
    return true;
  }

  /**
   * 日付の文字列をフォーマットする (yyyy-mm-dd -> yyyy/mm/dd)
   *
   * @param val 日付
   * @return string
   */
  private static formatDate(val: string) {
    if (val.length <= 0) {
      return "";
    }
    return val.replace(/-/g, "/");
  }
}
